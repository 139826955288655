<template>
  <footer class="main-footer">
    <strong>
      Copyright &copy; 2024
      <a href="http://www.hofladenwelt.de">Hofladenwelt.de</a>.
    </strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 2.0.33-b5
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped></style>
