<template>
  <aside class="main-sidebar sidebar-dark-darkblue elevation-4">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img
        src="./../../assets/img/Logo-LUKE-APP-weiss-transparent.png"
        alt="AdminLTE Logo"
        class="brand-image elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">&nbsp;</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
            <div class="pb-3 mt-3 mb-3 user-panel d-flex">
              <div class="image">
                <img
                  src="./../../assets/img/default-user.png"
                  class="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div class="info">
                <router-link to="/profile" class="d-block">{{ this.$auth.user().first_name}} {{this.$auth.user().last_name }}</router-link>
              </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
              <ul
                class="nav nav-pills nav-sidebar flex-column nav-child-indent nav-compact nav-flat"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >

                <app-menu-item v-for="item in menu" :menuItem="item" :key="item.name"></app-menu-item>


              </ul>
            </nav>

      
      <!-- /.sidebar-menu -->

      <!-- <div class="text-center">
        <b-button v-b-modal.modal_legende variant="success" class=" mt-5">Icon-Legende</b-button>

        <b-modal id="modal_legende" centered  title="Icon-Legende" :hide-footer=true>
            
            <div class="row" style="font-size: 14pt">
              <div class="col-2">
                <i class="fas fa-fw fa-eye"></i><br/>
                <i class="fas fa-fw fa-edit"></i> <br/>
                <i class="fas fa-fw fa-trash"></i><br/>
                <i class="fas fa-plus-circle"></i> /<i class="fas fa-fw fa-plus"></i> <br/>
                <i class="fas fa-filter"></i><br/>
                <i class="fas fa-fw fa-user"></i> <br/>
                <i class="fas fa-fw fa-clipboard-list"></i> <br/>
                <i class="fas fa-sync"></i><br/>
                <i class="fas fa-fw fa-truck"></i><br/>
                <i class="fas fa-fw fa-download"></i><br/>
                <i class="fas fa-fw fa-envelope"></i><br/>
                <i class="fas fa-redo"></i><br/>
                <i class="fas fa-truck"></i><br/>
              </div>

              <div class="col-10">
                Ansehen <br/>
                Bearbeiten <br/>
                Löschen <br/>
                Hinzufügen <br/>
                Filter <br/>
                Kunden bearbeiten<br/>
                Bestellung anlegen<br/>
                Neu laden <br/>
                Lieferantenbestellung <br/>
                Download <br/>
                Senden <br/>
                Neu generieren <br/>
                Tracking <br/>
              </div>
            </div>

        </b-modal>
      </div> -->

    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import MenuItem from './MenuItem.vue';
export default {
  name: "SidebarMenu",
  components: {
        'app-menu-item': MenuItem
  },

  data: function() {
    return {
      isMenuOpen: false,
      menu: [
        {
          name: "Dashboard",
          path: "/dashboard",
          icon: "fas fa-fw fa-th"
        },

        {
          name: "Neuigkeiten",
          path: "/news",
          icon: "fas fa-fw fa-newspaper",
          // rights: [
          //   'news.display',
          //   'topic.display',
          // ]
        },
        {
          name: "Boards",
          path: "/boards",
          icon: "fas fa-fw fa-chalkboard-teacher",
          rights: [
            'boards.display',
          ]
        },
        {
          name: "Kalender",
          path: "/calendar",
          icon: "fas fa-fw fa-calendar",
          // rights: [
          //   'boards.display',
          // ]
        },
        {
          name: "Basis",
          type: "header",
          rights: [
            'articles.display',
            'customers.display',
            'orders.display',
            'abos.display',
            'vouchers.display'
          ]
        },
        {
          name: "Artikel",
          path: "/articles",
          icon: "fas fa-fw fa-bookmark",
          rights: [
            'articles.display',
          ]
        },
        {
          name: "Kunden",
          path: "/customers",
          icon: "fas fa-fw fa-users",
          rights: [
            'customers.display'
          ]
        },
        {
          name: "Bestellungen",
          path: "/orders",
          icon: "fas fa-fw fa-clipboard",
          rights: [
            'orders.display'
          ]
        },
        {
          name: "Abos",
          path: "/abos",
          icon: "fas fa-fw fa-sync",
          rights: [
            'abos.display'
          ]
        },
        {
          name: "Angebote",
          path: "/offers",
          icon: "fas fa-fw fa-file-alt",
          rights: [
            'offers.display'
          ]
        },
        {
          name: "Gutscheine",
          path: "/vouchers",
          icon: "fas fa-fw fa-gift",
          rights: [
            'vouchers.display'
          ]
        },
        {
          name: "Erweitert",
          type: "header",
          rights: [
            'producers.display',
            'distributors.display',
            'dealers.display'
          ]
        },
        {
            name: 'Vertrieb',
            icon: "fas fa-fw fa-truck-moving",
            rights: [
              'producers.display',
              'distributors.display',
              'dealers.display'
            ],
            children: [
                {
                    name: 'Hersteller',
                    path: '/producers',
                    icon: "fas fa-fw fa-truck-loading",
                    rights: [
                      'producers.display'
                    ]
                },

                {
                    name: 'Lieferanten',
                    path: '/distributors',
                    icon: "fas fa-fw fa-truck",
                    rights: [
                      'distributors.display'
                    ]
                },

                {
                    name: 'Händler',
                    path: '/dealers',
                    icon: "fas fa-fw fa-dolly-flatbed",
                    rights: [
                      'dealers.display'
                    ]
                },
            ]
        },

        {
            name: 'Bestellwesen',
            icon: "fas fa-fw fa-paperclip",
            rights: [
                'supplier.orders.display'
            ],
            children: [
                {
                    name: 'Lieferantenbestellung',
                    path: '/supplier-orders',
                    icon: "fas fa-fw fa-dolly-flatbed",
                    rights: [
                        'supplier.orders.display'
                    ],
                },
                {
                    name: 'Filialbestellungen',
                    path: '/store-orders',
                    icon: "fas fa-fw fa-briefcase",
                    rights: [
                        'store.order.display'
                    ],
                },
                {
                    name: 'Händlerbestellungen',
                    path: '/dealer-orders',
                    icon: "fas fa-fw fa-truck",
                    rights: [
                        'dealer_orders.display'
                    ],
                },
            ]
        },

        {
            name: 'Buchhaltung',
            icon: "fas fa-fw fa-piggy-bank",
            rights: [
                'credits.display',
                'stornos.display',
                'datev.display'
            ],
            children: [
                {
                    name: 'Gutschriften',
                    path: '/credits',
                    icon: "fas fa-fw fa-hand-holding-usd",
                    rights: [
                        'credits.display'
                    ],
                },

                {
                    name: 'Rechnungskorrekturen',
                    path: '/stornos',
                    icon: "fas fa-fw fa-file-invoice-dollar",
                    rights: [
                        'stornos.display'
                    ],
                },

                {
                    name: 'Datev-Export',
                    path: '/datev',
                    icon: "fas fa-fw fa-download",
                    rights: [
                        'datev.display'
                    ],
                },
            ]
        },

        {
            name: 'Statistik',
            icon: "fas fa-fw fa-chart-area",
            rights: [
                'statistics.sales',
                'statistics.products'
            ],
            children: [
                {
                    name: 'Allgemein',
                    path: '/statistics/general',
                    icon: "fas fa-fw fa-chart-line",
                    rights: [
                        'statistics.general'
                    ],
                },
                {
                    name: 'Umsatz',
                    path: '/statistics/sales',
                    icon: "fas fa-fw fa-chart-line",
                    rights: [
                        'statistics.sales'
                    ],
                },
                {
                    name: 'Händler',
                    path: '/statistics/dealers',
                    icon: "fas fa-fw fa-chart-line",
                    rights: [
                        'statistics.sales'
                    ],
                },

                {
                    name: 'Artikelstatistik',
                    path: '/statistics/products',
                    icon: "fas fa-fw fa-chart-pie",
                    rights: [
                        'statistics.products'
                    ],
                },
            ]
        },
        {
            name: 'Marketing',
            icon: "fas fa-fw fa-heart",
            rights: [
                'labels.display'
            ],
            children: [
                {
                    name: 'Etiketten',
                    path: '/production/labels',
                    icon: "fas fa-fw fa-tags",
                    rights: [
                        'labels.display'
                    ],
                },
            ]
        },

        {
            name: 'Produktion',
            icon: "fas fa-fw fa-pizza-slice",
            rights: [
                'products.display',
                'wholesalers.display'
            ],
            children: [
                {
                    name: 'Großhändler',
                    path: '/production/wholesalers',
                    icon: "fas fa-fw fa-truck-moving",
                    rights: [
                        'products.display'
                    ],
                },
                {
                    name: 'Produkte',
                    path: '/production/products',
                    icon: "fab fa-fw fa-product-hunt",
                    rights: [
                        'wholesalers.display'
                    ],
                },
                {
                    name: 'Bestellungen',
                    path: '/wholesaler-orders',
                    icon: "fas fa-fw fa-truck-moving",
                    rights: [
                        'wholesalers.display'
                    ],
                },
            ]
        },
        {
            name: 'Streckenplanung',
            icon: "fas fa-route",
            path: '/routes',
            rights: [
              'routes.display'
            ],
        },
        {
            name: 'Personalverwaltung',
            icon: "fas fa-fw fa-pizza-slice",
            rights: [
                'employees.display',
            ],
            children: [
                {
                    name: 'Mitarbeiter',
                    path: '/management/employees',
                    icon: "fas fa-fw fa-truck-moving",
                    rights: [
                        'employees.display'
                    ],
                },
                {
                  name: "Mitarbeitergruppen",
                  path: '/management/employee-groups',
                  icon: 'fas fa-fw fa-users',
                  rights: [
                    'employee.groups.display'
                  ]
                },
                {
                    name: 'Abwesenheitsplaner',
                    path: '/management/holidays',
                    icon: "fas fa-cookie-bite",
                    rights: [
                        'employee.holidays.display'
                    ],
                },
                {
                    name: 'Arbeitsplan',
                    path: '/management/workplan',
                    icon: "fas fa-briefcase",
                    rights: [
                        'employee.workplan.display'
                    ],
                },
            ]
        },
        {
          name: "Lager",
          type: "header",
        },
        {
          name: "Regalverwaltung",
          path: "/shelves",
          // type: "timeTracking",
          icon: "fas fa-fw fa-pallet",
          // rights: [
          //   'time_tracking.display',
          // ]
        },
        {
          name: "Mitarbeiter",
          type: "header",
        },
        {
          name: "Arbeitszeiten",
          path: "/time-tracking",
          type: "timeTracking",
          icon: "fas fa-fw fa-clock",
          // rights: [
          //   'time_tracking.display',
          // ]
        },
        {
          name: "Abwesenheitsplaner",
          path: "/holiday-tracking",
          icon: "fas fa-fw fa-cookie-bite",
          // rights: [
          //   'time_tracking.display',
          // ]
        },
        {
          name: "Arbeitsplan",
          path: "/workplan",
          icon: "fas fa-fw fa-briefcase",
          // rights: [
          //   'time_tracking.display',
          // ]
        },
      ]
    };
  },
  computed: {
    authUser() {
      return this.$store.getters.getAuthUser;
    }
  },
  methods: {
    toogleMenu: function(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};
</script>

<style>
.main-sidebar .sidebar {
    overflow-y: scroll;
    overflow-x: hidden;
}</style>
